import main from "@/views/main.vue";

/**
 * 限定meta为必填
 * - title表示标题
 * - hideInMenu表示是否在菜单上显示，true隐藏， false显示，可为空
 * - icon表示菜单图标，可为空
 * - access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 */
export default [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
      hideInMenu: true,
    },
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/",
    name: "_home",
    redirect: "/home",
    meta: {
      title: "首页",
      hideInMenu: true,
    },
    component: main,
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          title: "首页",
          icon: "md-home",
          hideInMenu: true,
        },
        component: () => import("@/views/home.vue"),
      },
      {
        path: "/personal",
        name: "personal",
        meta: {
          title: "个人资料",
          hideInMenu: true,
        },
        component: () => import("@/views/personal/person.vue"),
      },
      {
        path: "/changePass",
        name: "changePass",
        meta: {
          title: "修改密码",
          hideInMenu: true,
        },
        component: () => import("@/views/personal/modifyPass.vue"),
      },
    ],
  },
  {
    path: "/system",
    name: "system",
    meta: {
      title: "系统管理",
      icon: "md-settings",
      access: ["super"],
    },
    component: main,
    children: [
      {
        path: "/system/manageUser",
        name: "manageUserManage",
        meta: {
          title: "管理员管理",
          icon: "md-people",
          parent: "system",
          access: ["super"],
        },
        component: () => import("@/views/system/manageUser.vue"),
      },
      {
        path: "/system/user",
        name: "userManage",
        meta: {
          title: "用户管理",
          icon: "md-person",
          parent: "system",
          access: ["super"],
        },
        component: () => import("@/views/system/user.vue"),
      },
      {
        path: "/company/branch",
        name: "branchManage",
        meta: {
          title: "机构列表",
          icon: "md-git-branch",
          parent: "company",
          // access: [ 'super' ]
        },
        component: () => import("@/views/company/branch.vue"),
      },
    ],
  },
  {
    path: "/company",
    name: "company",
    meta: {
      title: "学习管理",
      icon: "ios-book",
    },
    component: main,
    children: [
      {
        path: "/company/station",
        name: "stationManage",
        meta: {
          title: "岗位管理",
          icon: "md-git-branch",
          parent: "company",
        },
        component: () => import("@/views/company/station.vue"),
      },
      {
        path: "/company/station/course",
        name: "stationCourseManage",
        meta: {
          title: "岗位课程管理",
          parent: "company",
          hideInMenu: true,
        },
        component: () => import("@/views/company/stationCourse.vue"),
      },
      {
        path: "/company/learner",
        name: "learnerManage",
        meta: {
          title: "学员管理",
          icon: "md-school",
          parent: "company",
          // access: [ 'super' ]
        },
        component: () => import("@/views/company/learner.vue"),
        // }, {
        // 	path: '/company/learner/company',
        // 	name: 'learnerCompanyManage',
        // 	meta: {
        // 		title: '学员管理',
        // 		icon: 'md-school',
        // 		parent: 'company',
        // 		access: [ 'company' ]
        // 	},
        // 	component: () => import('@/views/company/learnerCompany.vue')
      },
      {
        path: "/company/subject",
        name: "subjectManage",
        meta: {
          title: "科目管理",
          icon: "md-cube",
          parent: "company",
        },
        component: () => import("@/views/company/subject.vue"),
      },

      {
        path: "/company/video",
        name: "videoManage",
        meta: {
          title: "视频管理",
          icon: "md-videocam",
          parent: "company",
          // access: [ 'super' ]
        },
        component: () => import("@/views/company/video.vue"),
        // }, {
        // 	path: '/company/video/company',
        // 	name: 'courseCompanyManage',
        // 	meta: {
        // 		title: '视频管理',
        // 		icon: 'md-videocam',
        // 		parent: 'company',
        // 		access: [ 'company' ]
        // 	},
        // 	component: () => import('@/views/company/videoCompany.vue')
      },
      {
        path: "/company/information",
        name: "informationManage",
        meta: {
          title: "资料管理",
          parent: "company",
          icon: "md-briefcase",
        },
        component: () => import("@/views/company/information.vue"),
      },
      {
        path: "/company/course",
        name: "courseManage",
        meta: {
          title: "课程管理",
          parent: "company",
          icon: "md-briefcase",
        },
        component: () => import("@/views/company/course.vue"),
      },
      {
        path: "/company/course/video",
        name: "courseVideoManage",
        meta: {
          hideInMenu: true,
          title: "课程视频管理",
          icon: "md-briefcase",
          parent: "company",
        },
        component: () => import("@/views/company/courseVideo.vue"),
      },
      {
        path: "/company/course/datum",
        name: "courseDatumManage",
        meta: {
          hideInMenu: true,
          title: "课程资料管理",
          icon: "md-briefcase",
          parent: "company",
        },
        component: () => import("@/views/company/courseDatum.vue"),
      },
      {
        path: "/company/point",
        name: "pointManage",
        meta: {
          title: "积分管理",
          icon: "md-briefcase",
          parent: "company",
          access: ["super"],
        },
        component: () => import("@/views/company/point.vue"),
      },
    ],
  },

  {
    path: "/live",
    name: "live",
    meta: {
      title: "轮播管理",
      icon: "md-folder",
    },
    component: main,
    children: [
      {
        path: "/live/list",
        name: "liveManage",
        meta: {
          title: "轮播列表",
          icon: "md-document",
          parent: "live",
        },
        component: () => import("@/views/live/live.vue"),
      },
    ],
  },
  {
    path: "/exam",
    name: "exam",
    meta: {
      title: "考试管理",
      icon: "md-folder",
    },
    component: main,
    children: [
      {
        path: "/exam/bank",
        name: "bankManage",
        meta: {
          title: "题库管理",
          icon: "md-document",
          parent: "exam",
          // access: [ 'super' ]
        },
        component: () => import("@/views/exam/bank.vue"),
        // }, {
        // 	path: '/exam/bank/company',
        // 	name: 'bankCompanyManage',
        // 	meta: {
        // 		title: '题库管理',
        // 		icon: 'md-document',
        // 		parent: 'exam',
        // 		access: [ 'company' ]
        // 	},
        // 	component: () => import('@/views/exam/bankCompany.vue')
      },
      {
        path: "/exam/question",
        name: "questionManage",
        meta: {
          title: "题目管理",
          icon: "md-document",
          parent: "exam",
        },
        component: () => import("@/views/exam/question.vue"),
      },
      {
        path: "/exam/strategy",
        name: "strategyManage",
        meta: {
          title: "策略管理",
          icon: "md-document",
          parent: "exam",
        },
        component: () => import("@/views/exam/strategy.vue"),
      },
      {
        path: "/exam/paper",
        name: "paperManage",
        meta: {
          title: "试卷管理",
          icon: "md-document",
          parent: "exam",
        },
        component: () => import("@/views/exam/paper.vue"),
      },
      {
        path: "/exam/list",
        name: "examManage",
        meta: {
          title: "考试管理",
          icon: "md-document",
          parent: "exam",
          // access: [ 'super' ]
        },
        component: () => import("@/views/exam/exam.vue"),
        // }, {
        // 	path: '/exam/list/company',
        // 	name: 'examCompanyManage',
        // 	meta: {
        // 		title: '考试管理',
        // 		icon: 'md-document',
        // 		parent: 'exam',
        // 		access: [ 'company' ]
        // 	},
        // 	component: () => import('@/views/exam/examCompany.vue')
      },
      {
        path: "/exam/detail",
        name: "examDetailManage",
        meta: {
          title: "考试详情",
          icon: "md-document",
          hideInMenu: true,
          parent: "exam",
        },
        component: () => import("@/views/exam/examDetail.vue"),
      },
    ],
  },

  {
    path: "/training",
    name: "training",
    meta: {
      title: "培训管理",
      icon: "md-barcode",
    },
    component: main,
    children: [
      {
        path: "/training/list",
        name: "trainingList",
        meta: {
          title: "培训管理",
          icon: "ios-basketball",
        },
        component: () => import("@/views/training/training.vue"),
      },
    ],
  },
  {
    path: "/order",
    name: "order",
    component: main,
    meta: {
      title: "订单管理",
      icon: "md-folder",
    },
    children: [
      {
        path: "/order",
        name: "orderManage",
        meta: {
          title: "订单管理",
          icon: "md-document",
          parent: "order",
          // access: [ 'super' ]
        },
        component: () => import("@/views/order/order.vue"),
        // }, {
        // 	path: '/order/company',
        // 	name: 'orderCompanyManage',
        // 	meta: {
        // 		title: '订单管理',
        // 		icon: 'md-document',
        // 		parent: 'order',
        // 		access: [ 'company' ]
        // 	},
        // 	component: () => import('@/views/order/orderCompany.vue')
      },
    ],
  },
  {
    path: "/statistic",
    name: "statistic",
    meta: {
      title: "统计管理",
      icon: "md-folder",
    },
    component: main,
    children: [
      {
        path: "/statistic/learner",
        name: "learnerStatistic",
        meta: {
          title: "学员统计",
          icon: "md-folder",
        },
        component: () => import("@/views/statistic/learner.vue"),
      },
    ],
  },
  {
    path: "/401",
    name: "error_401",
    meta: {
      hideInMenu: true,
    },
    component: () => import("@/views/error-page/401.vue"),
  },
  {
    path: "/500",
    name: "error_500",
    meta: {
      hideInMenu: true,
    },
    component: () => import("@/views/error-page/500.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "error_404",
    meta: {
      hideInMenu: true,
    },
    component: () => import("@/views/error-page/404.vue"),
  },
];
